// @ts-check
import React, { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TypographyOverflow from 'components/standard/TypographyOverflow';
import { useLeftHandLayout } from 'containers/LeftHandLayout';
import { Pencil } from 'lucide-react';

const COLLAPSED_HEADER_HEIGHT = 72;

/**
 * @param {object} props
 * @param {React.ReactNode} [props.actionsSlot]
 * @param {React.ReactNode} [props.attributesSlot]
 * @param {boolean} [props.isLoading]
 * @param {boolean} props.show
 * @param {string} props.title
 */
const CollapsedHeader = ({
	actionsSlot,
	attributesSlot,
	isLoading,
	show,
	title,
}) => {
	const { topInnerX, topSize, topOuterY } = useLeftHandLayout();

	return (
		<Box
			sx={{
				height: `${COLLAPSED_HEADER_HEIGHT}px`,
				display: 'flex',
				gap: 200,
				justifyContent: 'space-between',
				alignItems: 'center',
				visibility: show ? 'visible' : 'hidden',
				transition: 'ease-in-out 0.2s',
				opacity: show ? 1 : 0,
				top: `${topSize + topOuterY}px`,
				position: 'sticky',
				zIndex: (theme) => theme.zIndex.appBar - 1,
				paddingTop: 200,
				paddingX: `${topInnerX}px`,
				paddingBottom: 200,
				backgroundColor: (theme) => theme.palette.uc.bg.main,
				borderBottomStyle: 'solid',
				borderBottomWidth: '1px',
				borderBottomColor: ({ palette }) => palette.uc.border.weak,
				marginBottom: `-${COLLAPSED_HEADER_HEIGHT}px`,
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: 200,
					minWidth: 0,
					overflow: 'auto',
				}}
			>
				<TypographyOverflow color="uc.text.main" variant="body-md">
					{isLoading ? (
						<Skeleton
							variant="rounded"
							width={200}
							sx={(theme) => ({
								height: theme.size.sm,
							})}
						/>
					) : (
						title
					)}
				</TypographyOverflow>

				{attributesSlot && (
					<Box sx={{ display: 'flex', gap: 100 }}>{attributesSlot}</Box>
				)}
			</Box>
			{actionsSlot && <Box sx={{ gridArea: 'actions' }}>{actionsSlot}</Box>}
		</Box>
	);
};

/**
 * @param {boolean} hasAttributes
 * @param {boolean} hasActions
 */
const createGridTemplateAreas = (hasAttributes, hasActions) => {
	if (hasAttributes && hasActions) {
		return {
			xs: `
      'title title' 
      'attributes attributes' 
      'actions actions'
      `,
			md: `
      'title actions'
      'attributes attributes'
      `,
		};
	}

	if (!hasAttributes && hasActions) {
		return {
			xs: `
      'title title'
      'actions actions'
      `,
			md: `
      'title actions'
      `,
		};
	}

	if (hasAttributes) {
		return {
			xs: `
      'title title'
      'attributes attributes'
      `,
		};
	}

	return {
		xs: `'title title'`,
	};
};

/**
 * @param {object} props
 * @param {React.ReactNode} [props.actionsSlot]
 * @param {React.ReactNode} [props.attributesSlot]
 * @param {React.ReactNode} [props.breadcrumbsSlot]
 * @param {React.ReactNode} props.children
 * @param {React.ReactNode} [props.description]
 * @param {() => void} [props.onEditTitleClick]
 * @param {string} props.title
 * @param {boolean} [props.isLoading]
 */
const PageLayout = ({
	actionsSlot,
	attributesSlot,
	breadcrumbsSlot,
	children,
	description,
	onEditTitleClick,
	title,
	isLoading,
}) => {
	const { topInnerX, screenSize } = useLeftHandLayout();
	const [isAtTop, setIsAtTop] = useState(true);
	const standardHeaderRef = useRef(/** @type {HTMLDivElement | null} */ (null));
	const standardHeaderHeight = standardHeaderRef.current?.clientHeight;

	const isSmallScreen = screenSize === 'small';
	useEffect(() => {
		function handleScrollChange() {
			setIsAtTop(window.scrollY < (standardHeaderHeight || 150));
		}

		window.addEventListener('scroll', handleScrollChange);
		return () => {
			window.removeEventListener('scroll', handleScrollChange);
		};
	}, [standardHeaderHeight]);

	return (
		<Stack
			sx={{
				position: 'relative',
				flexGrow: 1,
			}}
		>
			{!isSmallScreen && (
				<CollapsedHeader
					actionsSlot={actionsSlot}
					attributesSlot={attributesSlot}
					isLoading={isLoading}
					show={!isAtTop}
					title={title}
				/>
			)}

			<Box
				sx={{
					marginBottom: { xs: 400, md: 600 },
					paddingTop: { xs: 300, md: 700 },
					paddingX: {
						xs: `${topInnerX}px`,
						md: 700,
					},

					transition: 'ease-in-out 0.2s',
					opacity: isAtTop || isSmallScreen ? 1 : 0,
					visibility: isAtTop || isSmallScreen ? 'visible' : 'hidden',
				}}
				ref={standardHeaderRef}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 200,
						maxWidth: '1200px',
						marginX: 'auto',
						width: '100%',
					}}
				>
					{breadcrumbsSlot && (
						<Stack alignItems="start">{breadcrumbsSlot}</Stack>
					)}
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: '1fr auto',
							gridTemplateAreas: createGridTemplateAreas(
								Boolean(attributesSlot),
								Boolean(actionsSlot),
							),
							columnGap: 200,
							rowGap: 200,
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: { xs: 50, md: 100 },
								gridArea: 'title',
								alignSelf: 'center',
								minWidth: 0,
							}}
						>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										minWidth: 0,
										overflow: 'auto',
									}}
								>
									<Box
										sx={{
											display: 'flex',
											gap: 50,
											alignItems: 'center',
											minWidth: 0,
										}}
									>
										<TypographyOverflow
											color="uc.text.main"
											sx={{
												typography: {
													xs: 'title-xs',
													md: 'title-lg',
												},
												fontWeight: { xs: 500, md: 500 },
												transition: 'ease-in-out 0.2s',
											}}
										>
											{isLoading ? (
												<Skeleton
													variant="rounded"
													width={250}
													sx={(theme) => ({
														height: theme.size.lg,
													})}
												/>
											) : (
												title
											)}
										</TypographyOverflow>
										{onEditTitleClick && (
											<IconButton
												variant="ghost"
												size="small"
												onClick={onEditTitleClick}
												sx={{ minWidth: (theme) => theme.size.md }}
											>
												<Pencil size={16} />
											</IconButton>
										)}
									</Box>
								</Box>
							</Box>

							{description && (
								<Box>
									<Typography
										sx={{ typography: { xs: 'body-sm', md: 'body-xl' } }}
									>
										{isLoading ? <Skeleton width={200} /> : description}
									</Typography>
								</Box>
							)}
						</Box>

						{attributesSlot && (
							<Box sx={{ display: 'flex', gap: 100, flexWrap: 'wrap' }}>
								{attributesSlot}
							</Box>
						)}

						{actionsSlot && (
							<Box sx={{ gridArea: 'actions' }}>{actionsSlot}</Box>
						)}
					</Box>
				</Box>
			</Box>

			<Stack
				sx={{
					transition: 'ease-in-out 0.2s',
					paddingX: { md: 700 },
					paddingBottom: 700,
					flexGrow: 1,
				}}
			>
				<Stack maxWidth="1200px" marginX="auto" width="100%" flexGrow={1}>
					{children}
				</Stack>
			</Stack>
		</Stack>
	);
};

export default PageLayout;
