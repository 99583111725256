import React, { lazy, Suspense } from 'react';
import {
	Redirect,
	Route,
	Switch,
	useLocation,
	Link as RouterLink,
} from 'react-router-dom';
import get from 'lodash/get';

import Errors from 'components/structural/Errors';
import { navMap } from 'config/routingConfig';
import CartRouter from 'components/routers/CartRouter';
import ChatRouter from 'components/routers/ChatRouter';
import ShopRouter from 'components/routers/ShopRouter';
import SupportRouter from 'components/routers/SupportRouter';
import GlobalComponents from 'components/structural/GlobalComponents';
import Loading from 'components/common/Loading';

import { ACCOUNT_CREATE_PATH } from 'components/routers/AccountRouter';
import InsecureVpnUsersDialog from 'components/common/InsecureVpnUesersDialog';
import LeftHandLayout from 'containers/LeftHandLayout';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import { roles } from 'utility/constants/roles';

const Cart = lazy(() => import('containers/pages/shop/Cart'));
const Logout = lazy(() => import('containers/structural/Logout'));
const Go = lazy(() => import('containers/structural/Go'));
const NotFound = lazy(() => import('pages/error/NotFound'));
const LiveChatLoading = lazy(
	() => import('containers/structural/LiveChatLoading'),
);
const LiveChatUnavailable = lazy(
	() => import('containers/structural/LiveChatUnavailable'),
);
const OpenIDReturn = lazy(() => import('containers/structural/OpenIDReturn'));
const OpenIDSilentReturn = lazy(
	() => import('components/structural/OpenIDSilentReturn'),
);
const News = lazy(() => import('containers/pages/login/News'));
const InvoicePDFDisplay = lazy(
	() => import('containers/pages/billing/invoice/InvoicePDFDisplay'),
);
const VNCConsole = lazy(
	() => import('containers/pages/servers/beyond-hosting/VNCConsole'),
);
const AccountCreate = lazy(() => import('containers/pages/account/create'));
const AccessDenied = lazy(() => import('pages/error/AccessDenied'));
const SupportCase = lazy(() => import('containers/pages/support/ticket'));

const AlwaysRoutesRaw = () => [
	<Route key="shopRoute" path="/shop">
		<ShopRouter navData={get(navMap, 'shop')} />
	</Route>,
	<Route key="cartBasketUuid" exact path="/cart/:basketUuid?">
		<Cart />
	</Route>,
	// legacy support for "saved baskets"
	<Route key="cartSavedBasketUuid" exact path="/cart/saved/:basketUuid">
		<Cart />
	</Route>,
];

const AuthenticatedRoutes = () => (
	<>
		<Switch>
			<Route path="/logout">
				<Logout />
			</Route>
			<Route path="/go/:uniqId" render={(props) => <Go {...props} />} />
			<Route path="/account/create">
				<AccountCreate />
			</Route>
			{Object.values(navMap).map((rootNav) => (
				<Route
					exact={get(rootNav, 'isExact')}
					key={get(rootNav, 'path')}
					path={get(rootNav, 'path')}
					render={get(rootNav, 'router')}
				/>
			))}
			<Route path="/cart-login">
				<Redirect to="/cart" />
			</Route>
			<Route path="/cart">
				<CartRouter />
			</Route>
			<Route path="/chat">
				<ChatRouter />
			</Route>
			<RoleRestrictedRoute
				// legacy route
				exact
				whitelistedRoles={[
					roles.PURCHASER,
					roles.TECHNICIAN,
					roles.SUSPENDED_OWNER,
				]}
				path="/support/mytickets/:ticket"
				component={({ match }) => (
					<Redirect to={`/support/mycases/${match.params.ticket}`} />
				)}
			/>
			<RoleRestrictedRoute
				exact
				whitelistedRoles={[
					roles.PURCHASER,
					roles.TECHNICIAN,
					roles.SUSPENDED_OWNER,
				]}
				path="/support/mycases/:ticket"
				component={SupportCase}
			/>
			<Route path="/support">
				<SupportRouter />
			</Route>
			<Route path="/access-denied">
				<AccessDenied />
			</Route>
			<Route>
				<NotFound />
			</Route>
		</Switch>
		<InsecureVpnUsersDialog />
	</>
);

const UnAuthenticatedRoutes = () => (
	<Switch>
		{AlwaysRoutesRaw()}
		{/* Land the user on a page that forces them to be authed */}
		<Route path="/" key="unauthed" render={() => <div />} />
	</Switch>
);

const KeroOnlyRoutes = () => (
	<Switch>
		{AlwaysRoutesRaw()}
		<Route path={ACCOUNT_CREATE_PATH}>
			<AccountCreate />
		</Route>
		<Route path="/logout">
			<Logout />
		</Route>
		<Redirect to={ACCOUNT_CREATE_PATH} />
	</Switch>
);

const externalSite = () => (
	<Switch>
		<Route path="/external/chatLoading">
			<LiveChatLoading />
		</Route>
		<Route path="/external/chatUnavailable">
			<LiveChatUnavailable />
		</Route>
		<Route path="/external/openid/return">
			<OpenIDReturn />
		</Route>
		<Route path="/external/openid/silent-return">
			<OpenIDSilentReturn />
		</Route>
		<Route path="/external/news">
			<News />
		</Route>
		<Route
			path="/external/invoicepdf/:id"
			render={(props) => <InvoicePDFDisplay {...props} />}
		/>
		<Route
			path="/external/vnc-console/:uniqId"
			render={(props) => <VNCConsole {...props} />}
		/>
		<Route>
			<NotFound />
		</Route>
	</Switch>
);

const Routes = ({ isLoggedIn, isKeroOnly }) => {
	if (isLoggedIn && isKeroOnly) return <KeroOnlyRoutes />;
	if (isLoggedIn) return <AuthenticatedRoutes />;
	return <UnAuthenticatedRoutes />;
};

const PageStructure = ({ isLoggedIn, isKeroOnly, isBasketAdmin }) => {
	const { pathname } = useLocation();

	return (
		<>
			<LeftHandLayout
				appPath={pathname}
				linkComponent={RouterLink}
				linkProp="to"
				roles={{
					loggedIn: isLoggedIn,
					keroOnly: isKeroOnly,
					basketAdmin: isBasketAdmin,
				}}
				minHeight="100vh"
				minWidth="100vw"
				maxWidth="100vw"
			>
				<Suspense fallback={<Loading />}>
					<Routes isLoggedIn={isLoggedIn} isKeroOnly={isKeroOnly} />
				</Suspense>
			</LeftHandLayout>
			<Errors />
			<GlobalComponents />
		</>
	);
};

const RouteHandler = ({ isLoggedIn, isKeroOnly, isBasketAdmin }) => {
	return (
		<Suspense fallback={<Loading />}>
			<Switch>
				<Route path="/external" component={externalSite} />
				<Route>
					<PageStructure
						isLoggedIn={isLoggedIn}
						isKeroOnly={isKeroOnly}
						isBasketAdmin={isBasketAdmin}
					/>
				</Route>
			</Switch>
		</Suspense>
	);
};

export default RouteHandler;
