// @ts-check

/**
 * Decode jwt token
 * @template {*} T
 * @param {string} token
 * @returns {T}
 */
export function jwtDecode(token) {
	return JSON.parse(atob(token.split('.')[1]));
}

/**
 * Decode jwt token without throwing error
 * @template {*} T
 * @param {string} token
 * @param {T} defaultValue
 * @returns {T}
 */
export function tryJwtDecode(token, defaultValue = /** @type T */ (undefined)) {
	try {
		return jwtDecode(token) || defaultValue;
	} catch (e) {
		return defaultValue;
	}
}

export default jwtDecode;
