import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import Button from 'undercurrent/Button';

import { REDIRECT_PATH } from 'utility/constants/auth';

const SignInButton = () => {
	const { pathname } = useLocation();
	const userManager = useSelector(appConfigSelectors.getUserManager);

	const navigateToLogin = () => {
		sessionStorage.setItem(REDIRECT_PATH, pathname);
		userManager.signinRedirect();
	};

	return (
		<Button onClick={navigateToLogin} variant="secondary">
			Sign in
		</Button>
	);
};

export default SignInButton;
