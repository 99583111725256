// @ts-check
import Stack from '@mui/material/Stack';
import React from 'react';
import { ReactComponent as LogoText } from 'images/lw-text-logo.svg';
import useLeftHandLayout from '../context';

/**
 * @param {React.ComponentProps<typeof Stack>} props
 */
export function TopNavTitle(props) {
	const { title, roles } = useLeftHandLayout();

	return (
		<Stack direction="row" useFlexGap gap={100} {...props}>
			{roles.loggedIn ? title : <LogoText />}
		</Stack>
	);
}

export default TopNavTitle;
