// @ts-check
import React from 'react';
import { drawerClasses } from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import DrawerFooter from 'undercurrent/DrawerFooter';
import useLeftHandLayout from '../context';
import SmallNavAccount from './Account';
import LeftNavList from '../LeftNav/List';
import SmallNavCreateFooter from './CreateFooter';

export function SmallNavDrawer() {
	const { mobileOpen, setMobileOpen, topSize, allowLeftNav } =
		useLeftHandLayout();

	if (!allowLeftNav) return null;

	return (
		<SwipeableDrawer
			hideBackdrop
			open={mobileOpen}
			onOpen={() => setMobileOpen(true)}
			onClose={() => setMobileOpen(false)}
			component="nav"
			anchor="left"
			variant="temporary"
			sx={{
				pointerEvents: 'none',
				[`& .${drawerClasses.paper}`]: {
					pointerEvents: 'all',
					width: '100%',
					height: `calc(100vh - ${topSize}px)`,
					marginTop: `${topSize}px`,
					padding: 0,
					backgroundColor: (theme) => theme.palette.uc.bg.weak,
				},
			}}
		>
			<SmallNavAccount />
			<LeftNavList flex="0% 1 1" />
			<DrawerFooter sx={{ padding: 0 }}>
				<SmallNavCreateFooter />
			</DrawerFooter>
		</SwipeableDrawer>
	);
}

export default SmallNavDrawer;
