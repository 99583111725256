// @ts-check
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu, { menuClasses } from '@mui/material/Menu';
import { dividerClasses } from '@mui/material/Divider';
import pseudoClasses from 'undercurrent/classes/pseudo';
import ChevronIcon from 'undercurrent/icons/animated/Chevron';
import { IntroTarget, introTargets } from 'containers/introjs';
import { createMenuContent } from '../common/CreateMenuContent';
import useLeftHandLayout from '../context';

export function TopNavCreate() {
	const [menuElement, setMenuElement] = useState(
		/** @type {HTMLElement|undefined} */ (undefined),
	);
	const open = menuElement !== undefined;
	const { roles } = useLeftHandLayout();

	if (!roles.loggedIn) {
		return null;
	}

	return (
		<>
			<IntroTarget introId={introTargets.navDesktopCreateAndDeploy}>
				<Button
					color="highlight"
					variant={open ? 'primary' : 'secondary'}
					endIcon={<ChevronIcon open={open} />}
					onClick={({ currentTarget }) => setMenuElement(currentTarget)}
				>
					Create and deploy
				</Button>
			</IntroTarget>
			<Menu
				open={open}
				anchorEl={menuElement}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				onClose={() => setMenuElement(undefined)}
				sx={{
					[`& .${menuClasses.paper}`]: {
						padding: 100,
						paddingBottom: 0,
					},
					[`& .${menuClasses.list}`]: {
						minWidth: '280px',
						display: 'flex',
						flexDirection: 'column',
						gap: 100,
					},
					[`& .${menuClasses.list} .${dividerClasses.root}${pseudoClasses.important}`]:
						{
							marginX: -100,
							marginBottom: -100,
						},
					// After divider
					[`& .${menuClasses.list} .${dividerClasses.root} ~ *`]: {
						marginX: -100,
						paddingX: 300,
						paddingY: 200,
					},
				}}
			>
				{createMenuContent({ onClose: () => setMenuElement(undefined) })}
			</Menu>
		</>
	);
}

export default TopNavCreate;
