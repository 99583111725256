// @ts-check
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import useScreenSize from './screenSize';

/** @typedef LeftHandLayoutBreadcrumbsI
 * @property {string} label
 * @property {string} href
 */

/** @typedef LeftHandLayoutContextI
 * @property {React.ReactNode} title
 * @property {React.Dispatch<React.SetStateAction<LeftHandLayoutContextI["title"]>>} setBreadcrumbs
 * @property {LeftHandLayoutBreadcrumbsI[]} breadcrumbs
 * @property {React.Dispatch<React.SetStateAction<LeftHandLayoutContextI["breadcrumbs"]>>} setBreadcrumbs
 * @property {string | undefined} appPath
 * @property {string | undefined} path
 * @property {React.Dispatch<React.SetStateAction<LeftHandLayoutContextI["path"]>>} setPath
 * @property {ReturnType<typeof useScreenSize>} screenSize
 * @property {boolean} mobileOpen
 * @property {React.Dispatch<React.SetStateAction<LeftHandLayoutContextI["mobileOpen"]>>} setMobileOpen
 * @property {number} leftSize
 * @property {React.Dispatch<React.SetStateAction<LeftHandLayoutContextI["leftSize"]>>} setLeftSize
 * @property {number} topSize
 * @property {React.Dispatch<React.SetStateAction<LeftHandLayoutContextI["topSize"]>>} setTopSize
 * @property {number} topInnerX
 * @property {React.Dispatch<React.SetStateAction<LeftHandLayoutContextI["topInnerX"]>>} setTopInnerX
 * @property {number} topOuterY
 * @property {React.Dispatch<React.SetStateAction<LeftHandLayoutContextI["topOuterY"]>>} setTopOuterY
 * @property {JSX.Element} [linkComponent]
 * @property {string} [linkProp]
 * @property {Record<string, boolean>} roles
 * @property {boolean} allowLeftNav
 */

/** @type LeftHandLayoutContextI['roles'] */
const rolesDefault = {};

/** @type {React.Context<LeftHandLayoutContextI>} */
const context = createContext(/** @type {*} */ (null));

/** @returns {LeftHandLayoutContextI} */
export function useLeftHandLayout() {
	return useContext(context);
}

/**
 * @typedef LeftHandLayoutProviderPropsI
 * @property {LeftHandLayoutContextI["appPath"]} appPath
 * @property {LeftHandLayoutContextI["linkComponent"]} linkComponent
 * @property {LeftHandLayoutContextI["linkProp"]} linkProp
 * @property {LeftHandLayoutContextI["roles"]} [roles]
 * @property {React.ReactNode} children
 */

/**
 * @param {LeftHandLayoutProviderPropsI} props
 */
export function LeftHandLayoutProvider(props) {
	const {
		appPath,
		linkComponent,
		linkProp,
		roles = rolesDefault,
		children,
	} = props;

	const [title, setTitle] = useState('');
	const [breadcrumbs, setBreadcrumbs] = useState(
		/** @type LeftHandLayoutBreadcrumbsI[] */ ([]),
	);
	const [pathOverride, setPath] = useState(undefined);
	const [leftSize, setLeftSize] = useState(0);
	const [topSize, setTopSize] = useState(0);
	const [topInnerX, setTopInnerX] = useState(0);
	const [topOuterY, setTopOuterY] = useState(0);
	const [mobileOpen, setMobileOpen] = useState(false);

	const path = pathOverride || appPath;

	const screenSize = useScreenSize();
	const allowLeftNav = !roles.basketAdmin && roles.loggedIn;

	// Close mobile drawer when appPath changes
	useEffect(() => {
		if (appPath === undefined) return;
		setMobileOpen(false);
	}, [appPath]);

	const value = useMemo(
		() =>
			/** @type {LeftHandLayoutContextI} */
			({
				title,
				setTitle,
				breadcrumbs,
				setBreadcrumbs,
				appPath,
				roles,
				allowLeftNav,
				path,
				setPath,
				screenSize,
				mobileOpen,
				setMobileOpen,
				linkComponent,
				linkProp,
				leftSize,
				setLeftSize,
				topSize,
				setTopSize,
				topInnerX,
				setTopInnerX,
				topOuterY,
				setTopOuterY,
			}),
		[
			title,
			setTitle,
			breadcrumbs,
			setBreadcrumbs,
			appPath,
			roles,
			allowLeftNav,
			path,
			setPath,
			screenSize,
			mobileOpen,
			setMobileOpen,
			linkComponent,
			linkProp,
			leftSize,
			setLeftSize,
			topSize,
			setTopSize,
			topInnerX,
			setTopInnerX,
			topOuterY,
			setTopOuterY,
		],
	);

	return <context.Provider value={value}>{children}</context.Provider>;
}

export default useLeftHandLayout;
