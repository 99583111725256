// @ts-check
import React from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const Loader = () => (
	<Skeleton
		variant="rounded"
		sx={(theme) => ({
			height: theme.size.xS,
			width: theme.size.xXL,
			margin: 200,
		})}
	/>
);

const TabBarLoader = ({ cells = 5 }) => {
	const tableCells = [];
	for (let i = 0; i < cells; i += 1) {
		tableCells.push(<Loader key={i} />);
	}

	return <Box sx={{ display: 'flex' }}>{tableCells}</Box>;
};

export default TabBarLoader;
