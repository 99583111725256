import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.storm.template.list;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'BAREMETAL_MODEL_LIST',
		method: 'POST',
		url: '/baremetal/model/list.json',
	},
);

export {
	actions as baremetalModelListActions,
	reducer,
	sagas,
	selectors as baremetalModelListSelectors,
	additionalSagas as baremetalModelListAdditionalSagas,
};
