// @ts-check
import React from 'react';
import { modalClasses } from '@mui/material/Modal';
import { drawerClasses } from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useNavLeftSize } from '../navSetter';
import LeftNavHeader from './Header';
import LeftNavList from './List';
import LeftNavFooter from './Footer';
import useLeftHandLayout from '../context';

const LEFTNAV_SIZE_PX = 248;

export function LeftNav() {
	const { screenSize, mobileOpen, setMobileOpen, allowLeftNav } =
		useLeftHandLayout();

	const isPersistent = screenSize === 'large';

	useNavLeftSize(isPersistent && allowLeftNav ? LEFTNAV_SIZE_PX : 0);

	if (!allowLeftNav) return null;

	return (
		<SwipeableDrawer
			component="nav"
			open={isPersistent || mobileOpen}
			onOpen={() => setMobileOpen(true)}
			onClose={() => setMobileOpen(false)}
			variant={isPersistent ? 'persistent' : 'temporary'}
			anchor="left"
			sx={[
				{
					[`& .${drawerClasses.paper}`]: (theme) => ({
						marginLeft: theme.spacing(100),
						marginTop: theme.spacing(100),
						marginBottom: theme.spacing(100),
						height: `calc(100vh - ${theme.spacing(100)} * 2)`,
						alignItems: 'stretch',
						width: LEFTNAV_SIZE_PX,
						padding: '0!important',
					}),
					[`& .${modalClasses.backdrop}`]: {
						opacity: '0!important',
					},
				},
				isPersistent && {
					[`& .${drawerClasses.paper}`]: {
						boxShadow: 'none',
						transition: 'all 0s!important',
						bgcolor: 'transparent',
						border: 'none',
					},
				},
				!isPersistent && {
					[`& .${drawerClasses.paper}`]: (theme) => ({
						bgcolor: theme.palette.uc.bg.weak,
						borderRadius: theme.borderRadius.rounded,
					}),
				},
			]}
		>
			<LeftNavHeader />
			<LeftNavList flex="0% 1 1" overflow="auto" />
			<LeftNavFooter margin={100} />
		</SwipeableDrawer>
	);
}

export default LeftNav;
