// @ts-check

import React from 'react';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from 'undercurrent/Tooltip';
import { formatCurrency } from 'banana-stand/format';
import { hasAuthToken as getHasAuthToken } from 'modules/auth';
import useBillingBalance from 'modules/queries/billing/useBalance';
import useAccountDetails from 'modules/queries/account/useDetails';
import { IntroTarget, introTargets } from 'containers/introjs';
import TriangleAlertIcon from 'undercurrent/icons/lucide/TriangleAlert';
import FooterMenu from './FooterMenu';

/**
 * @param {object} props
 * @param {boolean} props.isPastDue
 * @param {string} props.pastDue
 * @param {React.ReactElement} props.children
 * */
const PastDueTooltip = ({ isPastDue, pastDue, children }) => {
	if (isPastDue) {
		return (
			<Tooltip
				variant="fancy"
				arrow
				title="Past due"
				message={
					<>
						A friendly reminder your payment of <strong>{pastDue}</strong> is
						due
					</>
				}
				placement="top"
			>
				{children}
			</Tooltip>
		);
	}

	return children;
};

/**
 * @param {React.ComponentProps<typeof Stack>} props
 */
export function LeftNavFooter(props) {
	const hasAuthToken = useSelector(getHasAuthToken);
	const { data: billingBalanceData, isError: billingBalanceError } =
		useBillingBalance({
			enabled: hasAuthToken,
		});
	const { data: accountUserDetailsData, isError: accountUserDetailsError } =
		useAccountDetails({});

	const accountNumber = accountUserDetailsData?.accnt;
	const balanceDue = formatCurrency(billingBalanceData?.accountBalanceDisplay);
	const pastDue = formatCurrency(billingBalanceData?.pastDue);
	const isPastDue = (billingBalanceData?.pastDue || 0) > 0;

	if (billingBalanceError && accountUserDetailsError) {
		// likely means the user does not have permission to view this data - so
		// just don't show anything
		return null;
	}

	return (
		<IntroTarget introId={introTargets.navAccountBalance}>
			<Stack flexDirection="column" {...props}>
				{!billingBalanceError && (
					<Stack
						direction="row"
						justifyContent="stretch"
						alignItems="center"
						padding={100}
						paddingBottom={200}
						borderRadius={(theme) =>
							`${theme.borderRadius.roundedSm} ${theme.borderRadius.roundedSm} 0 0`
						}
						marginBottom={-100}
						gap={100}
						useFlexGap
						bgcolor={(theme) =>
							!isPastDue
								? theme.palette.uc.bg.medium
								: theme.palette.uc.danger.bg.main
						}
					>
						<PastDueTooltip pastDue={pastDue} isPastDue={isPastDue}>
							<Stack
								direction="row"
								flex="100% 1 1"
								alignItems="center"
								gap={50}
								useFlexGap
							>
								{isPastDue && (
									<TriangleAlertIcon
										sx={(theme) => ({
											color: theme.palette.uc.danger.icon.strong,
											height: theme.size.xS,
											width: theme.size.xS,
										})}
									/>
								)}
								<Typography variant="label-sm">
									Total due <strong>{balanceDue}</strong>
								</Typography>
							</Stack>
						</PastDueTooltip>
						<FooterMenu />
					</Stack>
				)}
				{!accountUserDetailsError && (
					<Stack
						flexDirection="row"
						justifyContent="stretch"
						alignItems="center"
						padding={100}
						border={(theme) => `1px solid ${theme.palette.uc.border.main}`}
						borderRadius={(theme) => theme.borderRadius.roundedSm}
						bgcolor={(theme) => theme.palette.uc.bg.weak}
					>
						<Typography flex="100% 1 1" variant="label-xs">
							Account ID {accountNumber}
						</Typography>
					</Stack>
				)}
			</Stack>
		</IntroTarget>
	);
}

export default LeftNavFooter;
