// @ts-check
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSnackbarError from 'utility/hooks/useSnackbarError';
import snackbarActions from 'modules/snackbar/snackbarActions';
import api from '../../api';

const useSupportRequestCreate = () => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const snackbarError = useSnackbarError();

	return useMutation({
		mutationFn:
			/** @param {import('club-sauce/public/support/request/index.raw').LWApiPublicSupportRequestCreateParamsRawI} params */
			(params) => api.support.request.apiRaw.create(params),
		onSuccess: (data, params) => {
			const ccList = params?.cc_list || [];

			if (typeof ccList === 'string') {
				return;
			}

			ccList.forEach((email) => {
				if (!data?.cc_list?.includes(email)) {
					dispatch(
						snackbarActions.pushMessage({
							message: `We were unable to save ${email} to the cc email list. Did you enter in a valid email?`,
							variant: 'error',
						}),
					);
				}
			});

			queryClient.invalidateQueries({
				queryKey: ['/support/request/list'],
			});
		},
		onError: snackbarError,
	});
};

export default useSupportRequestCreate;
