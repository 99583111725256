// @ts-check
import React from 'react';

import Stack from '@mui/material/Stack';
import useLeftHandLayout from 'containers/LeftHandLayout/context';
import { IntroTarget, introTargets } from 'containers/introjs';
import { useNavTopInnerX, useNavTopOuterY, useNavTopSize } from '../navSetter';
import TopNavSearch from '../common/SearchButton';
import CartButton from '../common/CartButton';
import TopNavCreate from './Create';
import TopNavSupport from '../common/SupportMenu';
import TopNavTitle from './Title';
import TopNavUser from '../common/UserMenu';
import MobileMenuButton from '../common/MobileMenuButton';

const TOPNAV_SIZE_PX = 64;
const TOPNAV_INNER_X_PX = 16;
const TOPNAV_OUTER_Y_PX = 8;

export function TopNav() {
	const { screenSize, leftSize, allowLeftNav } = useLeftHandLayout();
	const isLarge = screenSize === 'large';

	useNavTopSize(TOPNAV_SIZE_PX);
	useNavTopInnerX(TOPNAV_INNER_X_PX);
	useNavTopOuterY(TOPNAV_OUTER_Y_PX);

	return (
		<Stack
			component="nav"
			overflow="hidden"
			position="fixed"
			top={0}
			left={leftSize}
			right={0}
			padding={`${TOPNAV_OUTER_Y_PX}px`}
			paddingBottom={0}
			bgcolor={(theme) => theme.palette.uc.bg.weak}
			zIndex={(theme) => theme.zIndex.appBar}
		>
			<Stack
				height={TOPNAV_SIZE_PX}
				direction="row"
				padding={`${TOPNAV_INNER_X_PX}px`}
				alignItems="center"
				useFlexGap
				gap={200}
				borderRadius={(theme) =>
					`${theme.borderRadius.roundedSm} ${theme.borderRadius.roundedSm} 0 0`
				}
				borderBottom={(theme) => `1px solid ${theme.palette.uc.border.weak}`}
				bgcolor={(theme) => theme.palette.uc.bg.main}
				boxShadow={1}
			>
				{!isLarge && allowLeftNav && (
					<IntroTarget introId={introTargets.navMediumButton}>
						<MobileMenuButton showAlerts />
					</IntroTarget>
				)}
				<TopNavTitle flex="0% 1 1" overflow="auto" />
				<Stack direction="row" alignItems="center" useFlexGap gap={150}>
					<TopNavCreate />
					<CartButton />
					<TopNavSearch />
					<TopNavSupport />
					<TopNavUser />
				</Stack>
			</Stack>
		</Stack>
	);
}

export default TopNav;
