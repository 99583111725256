// @ts-check
import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import DrawerSupportCall from 'components/standard/DrawerSupportCall';
import PageLayout from 'components/standard/PageLayout';
import TabBar from 'components/standard/TabBar';
import { useLeftHandLayout } from 'containers/LeftHandLayout';
import chatActions from 'modules/chat/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from 'undercurrent/Button';
import MessageSquareIcon from 'undercurrent/icons/lucide/MessageSquare';

/**
 * A layout for support pages.
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {'/support' | '/support/status' | '/support/mycases'} props.tabsValue
 * @param {boolean} [props.isLoading]
 */
const SupportLayout = ({ children, tabsValue, isLoading }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { topInnerX } = useLeftHandLayout();

	const [isSupportCallOpen, setIsSupportCallOpen] = useState(false);

	/** @type {import('@mui/material').TabsProps['onChange']} */
	const handleTabsChange = (e, newValue) => {
		history.push(newValue);
	};

	return (
		<PageLayout
			title="Support Center"
			actionsSlot={
				<Box sx={{ display: 'flex', gap: 100 }}>
					<Button
						startIcon={<MessageSquareIcon />}
						onClick={() => dispatch(chatActions.startChat())}
						sx={{ flex: { xs: 1, md: 'none' } }}
					>
						Chat with us
					</Button>
					<Button
						variant="secondary"
						onClick={() => setIsSupportCallOpen(true)}
						sx={{ flex: { xs: 1, md: 'none' } }}
					>
						Call us
					</Button>
				</Box>
			}
			isLoading={isLoading}
		>
			<TabBar sx={{ marginBottom: 600 }} isLoading={isLoading}>
				<Tabs
					value={tabsValue}
					onChange={handleTabsChange}
					variant="scrollable"
				>
					<Tab value="/support" label="Common issues" />
					<Tab value="/support/status" label="Service status" />
					<Tab value="/support/mycases" label="My cases" />
				</Tabs>
			</TabBar>
			<Stack sx={{ paddingX: { xs: `${topInnerX}px`, md: 0 }, flexGrow: 1 }}>
				{children}
			</Stack>

			<DrawerSupportCall
				open={isSupportCallOpen}
				onClose={() => setIsSupportCallOpen(false)}
			/>
		</PageLayout>
	);
};

export default SupportLayout;
