import React from 'react';
import Badge from '@mui/material/Badge';
import Tooltip from 'undercurrent/Tooltip';
import IconButton from '@mui/material/IconButton';
import MenuIcon from 'undercurrent/icons/animated/Menu';
import useNotificationsCurrent from 'modules/queries/notification/useCurrent';
import useLeftHandLayout from '../context';

/**
 * @typedef MobileMenuButtonPropsI
 * @property {boolean} [showAlerts]
 */

export const MobileMenuButton = React.forwardRef(
	/**
	 * @param {React.ComponentProps<typeof IconButton> & MobileMenuButtonPropsI} props
	 */
	(props, ref) => {
		const { showAlerts, ...buttonProps } = props;

		const { mobileOpen, setMobileOpen } = useLeftHandLayout();

		const { data: alertsData } = useNotificationsCurrent(undefined, {
			refetchInterval: 30000,
		});
		const showDot = showAlerts && alertsData?.items?.length > 0;

		return (
			<Tooltip title="Menu" ref={ref}>
				<IconButton
					size="small"
					variant="ghost"
					onClick={() => {
						setMobileOpen((c) => !c);
					}}
					{...buttonProps}
				>
					<Badge
						color={showDot ? 'error' : undefined}
						overlap="circular"
						variant="dot"
					>
						<MenuIcon
							open={mobileOpen}
							sx={{ height: '16px', width: '16px' }}
						/>
					</Badge>
				</IconButton>
			</Tooltip>
		);
	},
);

MobileMenuButton.displayName = 'MobileMenuButton';

export default MobileMenuButton;
