import { take, spawn, takeLatest, put, select, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { actions as removeActions } from 'modules/api/asset/removeModule';
import { selectors as detailsSelectors } from 'modules/api/asset/detailsModule';
import snackBarSaga from 'modules/snackbar/sagas';
import { selectors as routeSelectors } from 'modules/route';
import destroyActions from './actions';

function* handleDestroyWithStatus({
	rating,
	cleanupAssets,
	acronisId,
	removeAcronis,
	cancellationComment,
	uniqId,
	cancellationReason,
	status,
}) {
	if (status !== 'Active') {
		yield call(snackBarSaga, {
			error: true,
			errorMessage: 'Cannot remove an asset that is not "Active"',
		});
		return;
	}
	if (removeAcronis) {
		yield put(removeActions.fetch({ uniq_id: acronisId }));
		yield take([removeActions.setType, removeActions.errorType]);
	}
	yield put(
		removeActions.fetch({
			rating,
			uniq_id: uniqId,
			cleanup_assets: cleanupAssets,
			cancellation_comment: cancellationComment,
			cancellation_reason: cancellationReason,
		}),
	);
}

function* handleDestroy(action) {
	const { payload } = action;
	yield call(handleDestroyWithStatus, {
		...payload,
		status: payload?.status || (yield select(detailsSelectors.status)),
	});
}

function* handleDestroyRedirect() {
	// redirect after destroy
	const pathname = yield select(routeSelectors.getPathName);
	if (pathname !== '/services/threatstack') {
		yield put(push({ pathname: `/servers` }));
	}
}

function* spawnHandleDestroy() {
	yield takeLatest(destroyActions.DESTROY_ON_DESTROY, handleDestroy);
}

function* spawnHandleRedirect() {
	yield takeLatest(removeActions.setType, handleDestroyRedirect);
}

export {
	spawnHandleRedirect,
	spawnHandleDestroy,
	handleDestroyRedirect,
	handleDestroy,
	handleDestroyWithStatus,
};
export default function* sagas() {
	yield spawn(spawnHandleDestroy);
	yield spawn(spawnHandleRedirect);
}
