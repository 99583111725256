import { useRef, useEffect } from 'react';

export const isMac = navigator.userAgent.includes('Mac');
export const CMD_KEY = isMac ? '⌘' : '^';

/**
 * @typedef {Object} KeyCmdOptionsI
 * @property {boolean} [enabled]
 * @property {boolean} [shiftKey]
 * @property {boolean} [altKey]
 * @property {Element} [target]
 */

/**
 * Interupt cmd/ctrl key combination
 * @param {string} code
 * @param {(event: KeyboardEvent) => void} callback
 * @param {KeyCmdOptionsI} [options]
 * @example
 * useKeyCmd('KeyP', handlePalette, { shiftKey: true });
 */
export function useKeyCmd(code, callback, options = {}) {
	const {
		enabled = true,
		shiftKey = false,
		altKey = false,
		target = document,
	} = options;

	// Prevent useEffect from firing off when callback isnt memo'd
	const callbackRef = useRef(callback);
	callbackRef.current = callback;

	useEffect(() => {
		if (!enabled) return undefined;

		/** @param {KeyboardEvent} ev */
		const handleKeyDown = (ev) => {
			if (!callbackRef.current) return;

			// Match key combination
			if (ev.code !== code) return;
			if (ev.shiftKey !== shiftKey) return;
			if (ev.altKey !== altKey) return;

			// Check ctrl/cmd key
			if (isMac && !(ev.metaKey && !ev.ctrlKey)) return;
			if (!isMac && !(!ev.metaKey && ev.ctrlKey)) return;

			// prevent browser default behavior (for example, focusing browser
			// search bar)
			ev.preventDefault();

			callbackRef.current(ev);
		};

		target.addEventListener('keydown', handleKeyDown);

		return () => {
			target.removeEventListener('keydown', handleKeyDown);
		};
	}, [enabled, code, shiftKey, altKey, target, callbackRef]);
}

export default useKeyCmd;
