import { useSelector } from 'react-redux';
import appConfigSelectors from 'modules/appConfig/selectors';
import { chatSelectors } from 'modules/chat';
import useChatMiaw from './useChatMiaw';

/** @typedef  ChatMiawGateProps
 * @property {React.ReactNode} children - The child elements to render
 */

/**
 * Gate component that initializes and manages the chat service across the application
 * @param {ChatMiawGateProps} props
 */
const ChatMiawGate = ({ children }) => {
	const config = useSelector(appConfigSelectors.getAppConfig);
	const { chatMiawConfig } = config || {};
	const chatIsActive = useSelector(chatSelectors.getIsActive);

	useChatMiaw(chatMiawConfig, {
		chatIsActive,
		enabled: chatMiawConfig.enabled,
	});

	return children;
};

export default ChatMiawGate;
