import React from 'react';
import { fromApiBoolean } from 'club-sauce';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import NewTabIcon from '@material-ui/icons/OpenInNewRounded';
import LWLink from 'components/common/LWLink';
import { actions as consoleActions } from 'modules/server/console';
import dialogActions from 'modules/dialogs/actions';
import vmwareActions from 'modules/vmware/actions';
import { selectors as vmwareLoginUrlSelectors } from 'modules/api/vmware/vcloud/vdc/loginUrlModule';
import { isUnknown } from 'utility/powerStatus';
import { SLWButton } from '../actionStyles';

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const FooterActions = ({
	isPrivateParent,
	isBeyondHosting,
	isVmware,
	isOpenStack,
	assetType: serverType,
	status,
	uniq_id: uniqId,
	ip,
	capabilities,
	powerStatus,
	categories,
	type: productCode,
}) => {
	const dispatch = useDispatch();

	// TODO DEV-4513 canUseNoVNCConsole and scrap productCode and category
	const hasConsole =
		fromApiBoolean(capabilities?.canUseSpiceConsole) ||
		['Dedicated', 'AutoDedicated'].some((category) =>
			categories?.includes(category),
		);
	const disableConsole =
		isUnknown(powerStatus) ||
		['Bare.Metal.WIN', 'Bare.Metal.GPU.WIN'].includes(productCode);

	const isLoadingvmWareLink = useSelector(vmwareLoginUrlSelectors.isLoading);

	const openConsole = () => {
		dispatch(
			consoleActions.openConsole({
				uniqId,
			}),
		);
	};

	const history = useHistory();

	const isCpanel = fromApiBoolean(capabilities?.cpanelLogin);
	const isInterworx = fromApiBoolean(capabilities?.interworxLogin);
	const isPlesk = fromApiBoolean(capabilities?.pleskLogin);
	const isCredentialsNotCustomerFacing = fromApiBoolean(
		capabilities?.credentialsNotCustomerFacing,
	);

	const isCloud =
		serverType === 'cloud' &&
		!isOpenStack &&
		!isBeyondHosting &&
		!isPrivateParent;

	const resizeServer = () => history.push(`/servers/${uniqId}/resize`);

	const createPath = () => {
		switch (serverType) {
			case 'bare metal':
			case 'cloud':
			case 'dedicated':
				return `/servers/${uniqId}`;
			default:
				return `/go/${uniqId}`;
		}
	};

	return (
		<Box mt={2} mb={-1} pt={1} borderTop={1} borderColor="grey.disabledOpaque">
			{(isBeyondHosting || isOpenStack) && (
				<SLWButton
					onClick={openConsole}
					variant="subtle"
					disabled={status !== 'Active'}
				>
					Console
				</SLWButton>
			)}
			{hasConsole && !disableConsole && (
				<LWLink to={`${createPath()}/console`} underline="none">
					<SLWButton variant="subtle">Console</SLWButton>
				</LWLink>
			)}
			{isCpanel && (
				<SLWButton
					onClick={() =>
						window.open(`https://${ip}:2083`, '_blank', 'noopener')
					}
					variant="subtle"
					endIcon={<NewTabIcon />}
					disabled={status !== 'Active'}
				>
					Go to cPanel
				</SLWButton>
			)}
			{isCpanel && (
				<SLWButton
					onClick={() =>
						window.open(`https://${ip}:2087`, '_blank', 'noopener')
					}
					variant="subtle"
					endIcon={<NewTabIcon />}
					disabled={status !== 'Active'}
				>
					Go to WHM
				</SLWButton>
			)}
			{isInterworx && (
				<SLWButton
					onClick={() =>
						window.open(`https://${ip}:2443`, '_blank', 'noopener')
					}
					variant="subtle"
					endIcon={<NewTabIcon />}
					disabled={status !== 'Active'}
				>
					Go to Interworx
				</SLWButton>
			)}
			{isPlesk && (
				<SLWButton
					onClick={() =>
						window.open(`https://${ip}:8443`, '_blank', 'noopener')
					}
					variant="subtle"
					endIcon={<NewTabIcon />}
					disabled={status !== 'Active'}
				>
					Go to Plesk
				</SLWButton>
			)}
			{isVmware && (
				<SLWButton
					onClick={() => dispatch(vmwareActions.openVmwarePortal({ uniqId }))}
					variant="subtle"
					endIcon={<NewTabIcon />}
					disabled={status !== 'Active'}
					isLoading={isLoadingvmWareLink}
				>
					Go to VMWare
				</SLWButton>
			)}
			{!isCredentialsNotCustomerFacing && !isPrivateParent && (
				<SLWButton
					onClick={() =>
						dispatch(
							dialogActions.open({
								title: 'Password & Username',
								contentKey: 'ServerCredentialsDialogContents',
								contentProps: { uniqId, ip },
							}),
						)
					}
					variant="subtle"
					disabled={status !== 'Active'}
				>
					Password & Username
				</SLWButton>
			)}
			{!isCredentialsNotCustomerFacing && !isPrivateParent && (
				<LWLink to={`${uniqId}/support-access`} underline="none">
					<SLWButton variant="subtle" disabled={status !== 'Active'}>
						Support Access
					</SLWButton>
				</LWLink>
			)}
			{(isBeyondHosting || isCloud || isOpenStack) && !isVmware && (
				<SLWButton
					onClick={resizeServer}
					variant="subtle"
					disabled={status !== 'Active'}
				>
					Resize
				</SLWButton>
			)}
		</Box>
	);
};

export default FooterActions;
