import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import account from './account/accountReducer';
import acronis from './acronis/acronisReducer';
import asset from './asset/assetReducer';
import baremetal from './baremetal/reducer';
import billing from './billing/billingReducer';
import contact from './contact/contactReducer';
import domain from './domain/domainReducer';
import licensing from './licensing/licensingReducer';
import market from './market/reducer';
import monitoring from './monitoring/monitoringReducer';
import network from './network/networkReducer';
import notifications from './notifications/notificationsReducer';
import product from './product/productReducer';
import project from './project/projectReducer';
import salesforce from './salesforce/salesforceReducer';
import server from './server/serverReducer';
import sslCertificate from './ssl/certificate/sslCertificateReducer';
import storage from './storage/storageReducer';
import storm from './storm/stormReducer';
import support from './support/supportReducer';
import usertask from './usertask/usertaskReducer';
import utilities from './utilities/utilitiesReducer';
import vmware from './vmware/vmwareReducer';
import vpn from './vpn/vpnReducer';

export default combineReducers({
	account,
	acronis,
	asset,
	baremetal,
	billing,
	contact,
	domain,
	form,
	licensing,
	market,
	monitoring,
	network,
	notifications,
	product,
	project,
	salesforce,
	server,
	sslCertificate,
	storage,
	storm,
	support,
	usertask,
	utilities,
	vmware,
	vpn,
});
