// @ts-check

import { useMemo, useRef, useState } from 'react';
import { createAutocomplete } from '@algolia/autocomplete-core';
import { liteClient } from 'algoliasearch/lite';
import { useSelector } from 'react-redux';
import { selectors as appConfigSelectors } from 'modules/appConfig';

const INDEX_NAME = 'wp_posts_lw_help_doc';
const HITS_PER_PAGE = 5;
export const SEARCH_PATH = '/help-docs/search/';

/**
 * @typedef {import('@algolia/autocomplete-core').AutocompleteState<SearchResultItemType>} AutocompleteState
 * @typedef {import('@algolia/autocomplete-core').AutocompleteApi<SearchResultItemType>} AutocompleteApi
 * @typedef {import('../components/SearchResults').SearchResultItemType} SearchResultItemType
 */

/**
 * Fetches post items from Algolia search
 * @param {import('algoliasearch/lite').LiteClient} searchClient - Algolia search client instance
 * @param {string} query - Search query string
 * @returns {Promise<Array<SearchResultItemType>>} Array of post items
 */
const getPostItems = (searchClient, query) =>
	searchClient
		.search([
			{
				indexName: INDEX_NAME,
				// @ts-expect-error - Algolia types are not fully compatible
				query,
				params: {
					hitsPerPage: HITS_PER_PAGE,
					attributesToRetrieve: [
						'post_title',
						'post_excerpt',
						'objectID',
						'content_type',
						'permalink',
					],
				},
			},
		])
		// @ts-expect-error - Response type is correct at runtime
		.then(({ results }) => results[0].hits);

/**
 * Custom hook for handling search autocomplete functionality
 * @param {Object} props - Hook properties
 * @param {() => void} props.onClose - Callback function to close the search modal
 * @returns {{
 *   inputRef: import('react').RefObject<HTMLInputElement>,
 *   formRef: import('react').RefObject<HTMLFormElement>,
 *   panelRef: import('react').RefObject<HTMLDivElement>,
 *   autocomplete: AutocompleteApi,
 *   autocompleteState: AutocompleteState,
 *   isFocused: boolean,
 *   activeInput: boolean,
 *   handleKeyDown: (event: KeyboardEvent) => void,
 *   inputProps: ReturnType<AutocompleteApi['getInputProps']>,
 *   formProps: ReturnType<AutocompleteApi['getFormProps']>,
 *   panelProps: ReturnType<AutocompleteApi['getPanelProps']>
 * }}
 */
export default function useSearchAutocomplete({ onClose }) {
	const applicationId = useSelector(appConfigSelectors.getAlgoliaApplicationId);
	const searchOnlyApiKey = useSelector(
		appConfigSelectors.getAlgoliaSearchOnlyApiKey,
	);
	const wwwHostname = useSelector(appConfigSelectors.getWwwHostname);

	const searchClient = useMemo(
		() => liteClient(applicationId, searchOnlyApiKey),
		[applicationId, searchOnlyApiKey],
	);

	const [autocompleteState, setAutocompleteState] = useState(
		/** @type {AutocompleteState} */ ({}),
	);
	const [isFocused, setIsFocused] = useState(false);
	const inputRef = useRef(null);
	const formRef = useRef(null);
	const panelRef = useRef(null);

	const autocomplete = useMemo(
		() =>
			/** @type {AutocompleteApi} */ (
				createAutocomplete({
					onStateChange({ state }) {
						setAutocompleteState(/** @type {AutocompleteState} */ (state));
					},
					getSources() {
						return [
							{
								sourceId: 'posts',
								getItems({ query }) {
									return getPostItems(searchClient, query);
								},
							},
						];
					},
					navigator: {
						navigate({ item, state }) {
							const url =
								item?.permalink ||
								(state?.query
									? `${wwwHostname}${SEARCH_PATH}${encodeURIComponent(state.query)}`
									: '');
							if (url) {
								window.open(url, '_blank')?.focus();
							}
						},
					},
				})
			),
		[searchClient, wwwHostname],
	);

	const activeInput =
		Boolean(autocompleteState.query) &&
		autocompleteState.activeItemId === null &&
		isFocused;

	const inputProps = autocomplete.getInputProps({
		inputElement: inputRef.current,
		onFocus: () => setIsFocused(true),
		onBlur: () => setIsFocused(false),
	});

	const formProps = autocomplete.getFormProps({
		formElement: formRef.current,
		inputElement: inputRef.current,
	});

	const panelProps = autocomplete.getPanelProps({
		panelElement: panelRef.current,
	});

	/** @param {KeyboardEvent} event */
	const handleKeyDown = (event) => {
		inputProps.onKeyDown(event);
		if (event.key === 'Escape') {
			onClose();
		} else if (event.key === 'Enter') {
			event.preventDefault();
			const { activeItemId } = autocompleteState;
			if (typeof activeItemId === 'number') {
				autocomplete.navigator.navigate({
					item: autocompleteState.collections[0]?.items[activeItemId] ?? null,
					state: autocompleteState,
					itemUrl: '',
				});
			} else {
				autocomplete.navigator.navigate({
					// @ts-expect-error - Algolia types are not fully compatible
					item: null,
					state: autocompleteState,
					itemUrl: '',
				});
			}
		}
	};

	return {
		inputRef,
		formRef,
		panelRef,
		autocomplete,
		autocompleteState,
		isFocused,
		activeInput,
		handleKeyDown,
		inputProps,
		formProps,
		panelProps,
	};
}
