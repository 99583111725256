import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import { roles } from 'utility/constants/roles';
import { useLocation, withRouter } from 'react-router';
import dialogActions from 'modules/dialogs/actions';
import { connect } from 'react-redux';
import LWDialog from 'components/common/LWDialog';
import snackbarActions from 'modules/snackbar/snackbarActions';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';

import {
	actions as authActions,
	selectors as authSelectors,
} from 'modules/api/support/request/authenticateModule';
import LWTypography from 'components/common/LWTypography';
import { username as usernameSelector } from 'modules/auth/authSelectors';
import SupportLayout from 'components/standard/SupportLayout';

const MyCases = lazy(() => import('containers/pages/support/MyCases'));
const FeaturedHelpDocs = lazy(() => import('containers/pages/support'));
const LiquidWebStatusReports = lazy(
	() => import('containers/pages/support/LiquidWebStatusReports'),
);

const AuthenticateSupportRequest = ({
	hasFetched,
	hasError,
	snackbar,
	isLoading,
	authenticate,
	loggedInUser,
	history,
}) => {
	const url = new URL(window.location.href);
	const id = url.searchParams.get('case_id') || url.searchParams.get('id');
	const close = () => history.push('/');
	React.useEffect(() => {
		if (!id) {
			close();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history]);
	React.useEffect(() => {
		if (hasFetched) {
			close();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasFetched]);
	React.useEffect(() => {
		if (hasError) {
			snackbar('Error authenticating');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasError]);

	return (
		<LWDialog open title="Confirmation Needed" onClose={close}>
			<LWTypography>
				You are signed in as {loggedInUser}. Please confirm you would like to
				authenticate this support request with this account.
			</LWTypography>
			<ConfirmCancel
				isLoading={isLoading}
				cancel={close}
				confirm={() => authenticate(id, loggedInUser)}
			/>
		</LWDialog>
	);
};

const mapStateToProps = (state) => ({
	loggedInUser: usernameSelector(state),
	isLoading: authSelectors.isLoading(state),
	hasFetched: authSelectors.hasData(state),
	hasError: authSelectors.getError(state),
});

const mapDispatchToProps = (dispatch) => ({
	snackbar: (message) => dispatch(snackbarActions.pushMessage({ message })),
	openDialog: ({ title, content, dialogProps }) =>
		dispatch(dialogActions.open({ title, content, dialogProps })),
	authenticate: (id, username) => dispatch(authActions.fetch({ id, username })),
});

const ConnectedAuthenticateSupportRequest = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(AuthenticateSupportRequest));

const SupportRouter = () => {
	const { pathname } = useLocation();
	return (
		<SupportLayout tabsValue={pathname}>
			<Switch>
				<RoleRestrictedRoute
					// legacy route
					exact
					whitelistedRoles={[
						roles.PURCHASER,
						roles.TECHNICIAN,
						roles.SUSPENDED_OWNER,
					]}
					path="/support/mytickets"
					component={() => <Redirect to="/support/mycases" />}
				/>
				<RoleRestrictedRoute
					exact
					whitelistedRoles={[
						roles.PURCHASER,
						roles.TECHNICIAN,
						roles.SUSPENDED_OWNER,
					]}
					path="/support/mycases"
					component={MyCases}
				/>
				<RoleRestrictedRoute
					exact
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
					path="/support/request/authenticate"
					component={ConnectedAuthenticateSupportRequest}
				/>
				<RoleRestrictedRoute
					exact
					whitelistedRoles={[
						roles.PURCHASER,
						roles.TECHNICIAN,
						roles.SUSPENDED_OWNER,
					]}
					path="/support/status"
					component={LiquidWebStatusReports}
				/>
				<RoleRestrictedRoute
					exact
					whitelistedRoles={[
						roles.PURCHASER,
						roles.TECHNICIAN,
						roles.SUSPENDED_OWNER,
					]}
					path="/support"
					component={FeaturedHelpDocs}
				/>
			</Switch>
		</SupportLayout>
	);
};

export default SupportRouter;
