// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {object} [options]
 * @param {import('@tanstack/react-query').UseQueryOptions['enabled']} [options.enabled]
 */
const useAccountChatPasswordGet = ({ enabled } = {}) =>
	useQuery({
		queryKey: ['/account/chatPassword/get'],
		queryFn: () => api.account.chatPassword.apiRaw.get(),
		enabled,
	});

export default useAccountChatPasswordGet;
