// @ts-check
import { useNavTitle, useNavBreadcrumbs, useNavActivePath } from './navSetter';

/**
 * @typedef NavStatePropsI
 * @property {ReturnType<import('./context').default>["title"]} [title]
 * @property {ReturnType<import('./context').default>["breadcrumbs"]} [breadcrumbs]
 * @property {ReturnType<import('./context').default>["path"]} [path]
 */

/**
 * Set the nav states
 * @param {NavStatePropsI} props
 */
export function NavState(props) {
	const { title = '', breadcrumbs = [], path = undefined } = props;
	useNavTitle(title);
	useNavBreadcrumbs(breadcrumbs);
	useNavActivePath(path);

	return null;
}

export default NavState;
