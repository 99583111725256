import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CopyIcon from 'undercurrent/icons/animated/Copy';
import useAccountDetails from 'modules/queries/account/useDetails';
import useDelayReset from 'utility/hooks/useDelayReset';
import Tooltip from 'undercurrent/Tooltip';

export function SmallNavAccount() {
	const [copied, setCopied] = useState(false);

	const { data: accountUserDetailsData } = useAccountDetails({});

	const accountNumber = accountUserDetailsData?.accnt;

	const handleCopy = () => {
		navigator.clipboard.writeText(accountNumber);
		setCopied(true);
	};

	// Remove check mark after 2s
	useDelayReset(copied, () => setCopied(false), 2000);

	return (
		<Stack
			direction="row"
			paddingY={0}
			paddingX={300}
			justifyContent="space-between"
			alignItems="center"
		>
			<Typography variant="label-lg" flex="0% 1 1">
				Account ID {accountNumber}
			</Typography>
			<Tooltip open={copied} title="Copied" placement="left">
				<IconButton size="small" onClick={handleCopy}>
					<CopyIcon done={copied} />
				</IconButton>
			</Tooltip>
		</Stack>
	);
}

export default SmallNavAccount;
