/**
 * Useful links:
 * APIS:       https://developer.salesforce.com/docs/service/messaging-web/guide/api-overview.html
 * Settings:   https://developer.salesforce.com/docs/service/messaging-web/references/m4w-reference/settingsAPI.html
 */

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useScript } from 'usehooks-ts';
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';
import { tryJwtDecode } from 'utility/decodeJwt';

/** @typedef ChatMiawConfig
 * @property {string} orgId - The Salesforce organization ID
 * @property {string} deploymentId - The deployment ID for the chat service
 * @property {string} communityURL - The community URL for the chat service
 * @property {string} scrt2URL - The SCRT2 URL for the chat service
 * @property {string} scriptURL - The URL of the chat service script
 * @property {ChatSettings} settings - The chat settings
 */

/** @typedef ChatSettings
 * @property {string} chatButtonPosition - The position of the chat button (e.g  "25px,30px")
 * @property {boolean} hideChatButtonOnLoad - Whether to hide the chat button on load
 * @property {string} language - The language to use for the chat
 */

/** @typedef ChatMiawParams
 * @property {boolean} [chatIsActive] - Whether the legacy chat is currently active
 * @property {boolean} [enabled] - Whether the chat is enabled
 */

const DEFAULT_CHAT_SETTINGS = {
	language: 'en_US',
	chatButtonPosition: '32px,32px',
};

/**
 * Validates the chat configuration object
 * @param {ChatMiawConfig} config - The configuration to validate
 * @throws {Error} If any required fields are missing
 * @returns {boolean} True if validation passes
 */
const validateConfig = (config) => {
	if (!config?.orgId) throw new Error('Missing orgId in chat configuration');
	if (!config?.deploymentId)
		throw new Error('Missing deploymentId in chat configuration');
	if (!config?.communityURL)
		throw new Error('Missing communityURL in chat configuration');
	if (!config?.scriptURL)
		throw new Error('Missing scriptURL in chat configuration');
	return true;
};

/**
 * Initializes the chat service with the given configuration and settings
 * @param {ChatMiawConfig} config - The chat configuration
 * @param {ChatSettings} settings - The chat settings to apply
 * @returns {Promise<boolean>} True if initialization succeeds
 * @throws {Error} If initialization fails
 */
const initializeChatService = async (config, settings) => {
	try {
		const isInitialized = window.embeddedservice_bootstrap?.utilAPI;
		if (isInitialized) return true;

		validateConfig(config);

		window.embeddedservice_bootstrap.settings = {
			...window.embeddedservice_bootstrap.settings,
			...settings,
		};

		await window.embeddedservice_bootstrap.init(
			config.orgId,
			config.deploymentId,
			config.communityURL,
			{
				scrt2URL: config.scrt2URL,
			},
		);

		return true;
	} catch (error) {
		console.error('Failed to initialize chat service:', error);
		throw error;
	}
};

/**
 * Manages chat visibility based on current state
 * @param {boolean} showButton - Whether to show the chat button
 * @param {boolean} legacyChatIsActive - Whether the legacy chat is currently active
 * @param {boolean} isReady - Whether the chat service is ready
 */
const manageChatVisibility = (showButton, legacyChatIsActive, isReady) => {
	if (!isReady) return;

	const utilAPI = window.embeddedservice_bootstrap?.utilAPI;
	if (!utilAPI) return;

	if (showButton) {
		utilAPI.showChatButton();
	} else {
		utilAPI.hideChatButton();
	}

	const chatElement = document.getElementById('embedded-messaging');
	if (!chatElement) return;

	chatElement.style.display = legacyChatIsActive ? 'none' : 'block';
};

/**
 * Custom hook to manage the chat button visibility and initialization
 * @param {ChatMiawConfig} config - The chat configuration
 * @param {ChatMiawParams} params - Additional parameters for chat configuration
 */
export default function useChatMiaw(config, params) {
	const { chatIsActive: legacyChatIsActive, enabled } = params;
	const location = useLocation();
	const scriptStatus = useScript(config?.scriptURL);
	const [ready, setReady] = useState(false);

	const isHomePage = location.pathname === '/';

	const showChatButton = isHomePage && legacyChatIsActive === false;

	// Initialize chat when script is loaded
	useEffect(() => {
		if (enabled && scriptStatus === 'ready' && config) {
			try {
				const settings = {
					...DEFAULT_CHAT_SETTINGS,
					...config.settings,
					hideChatButtonOnLoad: !showChatButton,
				};

				initializeChatService(config, settings);
			} catch (err) {
				console.error('Failed to initialize chat service:', err);
			}
		}
	}, [scriptStatus, config, showChatButton, enabled]);

	// Handle chat button visibility
	useEffect(() => {
		manageChatVisibility(showChatButton, legacyChatIsActive, ready);
	}, [showChatButton, legacyChatIsActive, ready]);

	// Handle chat authentication using useQuery
	const { data: salesforceContactId } = useQuery({
		queryKey: ['/salesforce/chat/auth/issuejwt'],
		queryFn: () => api.salesforce.chat.auth.apiRaw.issueJWT(),
		enabled: ready,
		select: (data) => {
			const decoded = tryJwtDecode(data?.jwt);
			return decoded?.salesforce_contact;
		},
	});

	// Handle auth data updates
	useEffect(() => {
		if (!salesforceContactId) return;
		window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
			ContactId: salesforceContactId,
		});
	}, [salesforceContactId]);

	// Handle API ready state
	useEffect(() => {
		const handleApiReady = () => setReady(true);

		window.addEventListener('onEmbeddedMessagingReady', handleApiReady);
		return () => {
			window.removeEventListener('onEmbeddedMessagingReady', handleApiReady);
		};
	}, []);
}
